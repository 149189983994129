<!--This is the Patients page, with the list of all available patients. -->
<template>
    <b-container fluid class="patient-list-container py-4">
        <b-row class="mb-4">
            <b-col
                class="d-flex flex-column align-items-start justify-content-between flex-grow flex-sm-row align-items-sm-center"
            >
                <h1 class="text-primary mb-0">
                    {{ t('patients') }}
                </h1>
                <b-button
                    class="main-navigation-button mt-3 mt-sm-0"
                    variant="primary"
                    to="NewPatient"
                    v-if="
                        checkPermissions(
                            {
                                [PERMISSIONS.CREATE_PATIENT]: PERMISSIONS_VALUES.ENABLED,
                            },
                            permissions,
                            currentUser.accessPermissions
                        )
                    "
                >
                    <b-icon-plus /> {{ t('addNewPatient') }}
                </b-button>
            </b-col>
        </b-row>

        <b-card>
            <b-row class="mb-2">
                <b-col xl="4" lg="5">
                    <h4 class="heavy mb-0">{{ t('patientActivity') }}</h4>
                    <p class="gray-dark mb-0">
                        {{ t('patientListDescription') }}
                    </p>
                    <p class="gray-dark" v-if="isDefaultSort">
                        {{ t('patientListDescription2') }}
                    </p>
                    <p v-else class="hidden-element">
                        {{ t('patientListDescription2') }}
                    </p>
                </b-col>

                <b-col
                    xl="8"
                    lg="7"
                    class="d-flex align-items-sm-start justify-content-between flex-column flex-sm-row"
                >
                    <b-form
                        @submit.prevent="refreshRecords(1)"
                        class="d-flex flex-column flex-lg-column flex-md-row flex-xl-row order-1"
                    >
                        <b-input-group class="col-lg-12 col-xl-8 px-0 mr-2 mb-2 mb-xl-0">
                            <b-input-group-prepend is-text>
                                <b-icon icon="search"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input
                                type="search"
                                v-model="queryParams.search"
                                trim
                                :placeholder="
                                    t(
                                        activeCustomer.hidePatientNames
                                            ? 'patientSearchNoNamePlaceholder'
                                            : 'patientSearchPlaceholder'
                                    )
                                "
                                id="searchInput"
                            ></b-form-input>
                        </b-input-group>

                        <div class="h-100 d-flex justify-content-between">
                            <div class="position-relative mr-2">
                                <date-range-picker
                                    ref="picker"
                                    :locale-data="{firstDay: 1, format: 'yyyy-mm-dd'}"
                                    :showWeekNumbers="true"
                                    :showDropdowns="true"
                                    :autoApply="true"
                                    tabindex="0"
                                    control-container-class="form-control reportrange-text d-flex"
                                    v-model="dateRange"
                                    @update="dateRangeChanged"
                                >
                                    <template v-slot:input="picker">
                                        <div
                                            style="min-width: 165px"
                                            class="d-flex align-items-center"
                                            :class="{'text-muted': !dateRangeHasValue}"
                                        >
                                            <template v-if="picker.startDate || picker.endDate">
                                                {{ picker.startDate | date }} -
                                                {{ picker.endDate | date }}
                                            </template>
                                            <template v-else>
                                                {{ t('dateHint') }} -
                                                {{ t('dateHint') }}
                                            </template>
                                            <b-icon
                                                v-if="dateRangeHasValue"
                                                style="right: 0"
                                                icon="x"
                                                scale="1.5"
                                                class="mr-2 position-absolute"
                                                @click.stop="resetDateRange"
                                            ></b-icon>
                                        </div>
                                    </template>
                                </date-range-picker>
                            </div>

                            <b-button variant="primary" type="submit">
                                {{ t('search') }}
                            </b-button>
                        </div>
                    </b-form>

                    <div class="h-100 d-flex align-items-start order-0 order-sm-2 mb-2 mb-sm-0">
                        <PatientFilters @change="refreshRecords(1, $event)" />
                        <b-button
                            @click="exportData('patients', t(exportFileName), exportParams)"
                            variant="secondary"
                        >
                            {{ t('export') }} <b-icon-box-arrow-right class="ml-1" />
                        </b-button>
                    </div>
                </b-col>
            </b-row>

            <PatientTable
                :queryParams="queryParams"
                @sort-changed="refreshRecords(1)"
                ref="patientTable"
                style="overflow-y: hidden"
            />

            <b-row>
                <b-col cols="2" offset="10">
                    <b-pagination
                        v-if="pageDetails.total"
                        @change="refreshRecords"
                        align="right"
                        size="sm"
                        :value="queryParams.page"
                        :total-rows="pageDetails.total"
                        :per-page="queryParams.perPage"
                        aria-controls="my-table"
                    ></b-pagination>
                </b-col>
            </b-row>
        </b-card>
        <p class="font-weight-lighter mt-2">
            <small>{{ t('disclaimer') }} </small>
        </p>
    </b-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import DateRangePicker from 'vue2-daterange-picker';
import omit from 'lodash/omit';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import isNil from 'lodash/isNil';
import date from '@/filters/date';
import PatientTable from './components/PatientTable.vue';
import PatientFilters from './components/PatientFilters.vue';
import {SortCategories} from '@/constants/patientlist';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

const defaultSort = {
    sortOrder: 'desc',
    sortBy: SortCategories.ACTIVITY,
};
const defaultQueryParams = () => ({
    search: null,
    startDate: null,
    endDate: null,
    page: 1,
    perPage: 10,
    ...defaultSort,
});

export default {
    name: 'PatientList',
    components: {
        DateRangePicker,
        PatientTable,
        PatientFilters,
    },
    data() {
        return {
            PERMISSIONS,
            PERMISSIONS_VALUES,
            queryParams: defaultQueryParams(),
            dateRange: {
                startDate: null,
                endDate: null,
            },
        };
    },
    computed: {
        ...mapGetters('user', ['activeCustomer', 'currentUser']),
        ...mapGetters('permissions', ['permissions']),
        ...mapGetters('patientList', ['pageDetails']),
        ...mapGetters('user', ['activeCustomer', 'activeDoctorId']),

        queryObject() {
            // Removing empty property
            return Object.keys(this.queryParams)
                .filter((key) => !isNil(this.queryParams[key]))
                .reduce((acc, key) => ({...acc, [key]: this.queryParams[key]}), {});
        },

        isDefaultSort() {
            return (
                defaultSort.sortOrder === this.queryParams.sortOrder &&
                defaultSort.sortBy === this.queryParams.sortBy
            );
        },

        exportParams() {
            return omit(this.queryObject, ['page', 'perPage']);
        },

        dateRangeHasValue() {
            return !!this.dateRange.startDate || !!this.dateRange.endDate;
        },
        exportFileName() {
            return 'patients';
        },
    },

    methods: {
        checkPermissions,
        ...mapActions('patientList', ['clearPatientList']),

        async refreshRecords(page, params = {}) {
            this.queryParams.page = page;
            this.queryParams = {...this.queryParams, ...params};
            await this.fetchPatientList();
        },

        async fetchPatientList() {
            this.$router
                .replace({
                    query: this.queryObject,
                })
                .catch(() => {});
            localStorage.setItem('pl-query-params', JSON.stringify(this.queryObject));
            await this.blockingRequest('patientList/fetchPatientList', this.queryObject);
        },

        configureDateRangePicker() {
            // making date range selector focusable
            this.$refs.picker.$el.setAttribute('tabindex', '0');
            // making trigger search on enter over range input selector
            this.$refs.picker.$el.onkeydown = (event) => {
                if (event.key === 'Enter') {
                    this.dateRangeChanged();
                    this.refreshRecords(1);
                }
            };
        },

        dateRangeChanged() {
            const {startDate, endDate} = this.dateRange;
            this.queryParams.startDate = startDate
                ? new Date(startDate.toDateString()).toJSON()
                : startDate;
            this.queryParams.endDate = endDate
                ? new Date(endDate.toDateString()).toJSON()
                : endDate;
        },

        resetDateRange() {
            this.dateRange.startDate = null;
            this.dateRange.endDate = null;
            this.dateRangeChanged(this.dateRange);
        },
    },
    created() {
        this.clearPatientList();
    },
    async mounted() {
        const storedParams = localStorage.getItem('pl-query-params');
        this.queryParams = merge(
            {surgeon: this.activeDoctorId},
            this.queryParams,
            JSON.parse(storedParams),
            this.$route.query
        );
        this.dateRange = pick(this.queryParams, ['startDate', 'endDate']);
        this.configureDateRangePicker();
        await this.fetchPatientList();
    },
};
</script>
<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
<style lang="scss">
.vue-daterange-picker:focus {
    outline: none;
    .reportrange-text {
        color: #495057;
        background-color: #ffffff;
        border-color: #59e1f1;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(16, 168, 186, 0.25);
    }
}
</style>
